/** @jsx jsx */
import { jsx } from 'theme-ui';
import PropTypes from 'prop-types';

// import logo from '../assets/images/logoLight.svg';
import { ReactComponent as Snake } from '../../assets/images/schlingeling.svg';

const Schlingeling = ({ leftColor, rightColor }) => (
  <Snake
    sx={{
      width: '114px',
      height: '10px',
      '@keyframes wave': {
        to: {
          transform: 'translate(-23px, 0px)',
        },
      },
      '.schlingeling_svg__wave': {
        animation: 'wave 1s linear infinite',
        animationDelay: '-0.25s',
      },
      '.schlingeling_svg__right': {
        fill: rightColor,
      },
      '.schlingeling_svg__left': {
        fill: leftColor,
      },
    }}
  />
);

Schlingeling.propTypes = {
  leftColor: PropTypes.string,
  rightColor: PropTypes.string,
};

Schlingeling.defaultProps = {
  leftColor: '#F2B952',
  rightColor: '#EA7049',
};

export default Schlingeling;
