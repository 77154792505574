import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Grid, Box, Flex } from 'theme-ui';
import Logo from '../Logo';
import Link from '../Link';
import Heading from '../Heading';
import Burger from '../Burger';

const Header = ({ appearance, showNav }) => {
  const color = appearance === 'dark' ? 'blue' : 'white';
  const [showMobileNav, setShowMobileNav] = useState(false);
  return (
    <Grid
      columns={['repeat(4, 1fr)', 'repeat(12, 1fr)']}
      gap={[3, 4]}
      sx={{ mx: [3, 8, 14] }}
    >
      <Box sx={{ gridColumn: ['1/ span 1', '1/ span 2'], mt: [4, 6], height: [10, 8] }}>
        <Logo appearance={appearance} />
      </Box>

      <Box
        sx={{
          gridColumn: ['2/ span 3', '4/ -1'],
          height: 14,
          pt: [4, 6],
          justifyContent: 'flex-end',
          color,
          display: ['flex'],
        }}
      >
        {showMobileNav ? (
          <>
            <Box
              sx={{
                ml: [5, null, null, 10],
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Link to="/projects">
                <Heading as="span" variant="h3" color={color}>
                  Projects
                </Heading>
              </Link>
            </Box>
            <Box
              sx={{
                ml: [5, null, null, 10],
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Link to="/about">
                <Heading as="span" variant="h3" color={color}>
                  About
                </Heading>
              </Link>
            </Box>
          </>
        ) : null}
        {showNav ? <Box
          sx={{
            display: ['block', null, null, 'none'],
            ml: 5,
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer'
          }}
        >
          <Burger
            clickHandler={() => {
              setShowMobileNav(!showMobileNav);
            }}
          />
        </Box> : null}
        
      </Box>
    </Grid>
  );
};

Header.propTypes = {
  appearance: PropTypes.oneOf(['dark', 'light']),
  showNav: PropTypes.bool,
};

Header.defaultProps = {
  appearance: 'dark',
  showNav: true,
};
export default Header;
