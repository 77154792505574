/** @jsx jsx */
import { jsx } from 'theme-ui';

import { useState } from 'react';
import { Flex, Box } from 'theme-ui';
import { motion } from 'framer-motion';
import Img from 'gatsby-image';
import Link from '../Link';
import Heading from '../Heading';

const NextProjectTeaser = ({
  slug,
  projectName,
  color: { hex: color },
  image,
  ...data
}) => {
  const [show, setShow] = useState(false);
  return (
    <Flex
      sx={{
        width: ['100vw', 70],
        height: [30],
        mx: 'auto',
        justifyContent: 'center',
        alignItems: 'stretch',
        transform: ['translateY(0px)', 'translateY(30px)'],
      }}
    >
      {/* <pre>{JSON.stringify(color, null, 2)}</pre> */}

      <Link to={slug} sx={{ display: 'block', width: '100%' }}>
        <motion.div
          animate={show ? 'show' : 'hide'}
          variants={{
            show: {
              transition: {
                staggerChildren: 0.1,
                // delayChildren: 0.5
              },
            },
          }}
          onMouseEnter={() => setShow(true)}
          onMouseLeave={() => setShow(false)}
          style={{
            display: 'flex',
            flexDirection: 'column',
            // backgroundColor: color,
            height: '100%',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            // margin: '10px',
            // backgroundImage: `url(${image})`,
            backgroundSize: 'cover',
            position: 'relative',
          }}
        >
          {/* <pre>{JSON.stringify(image, null, 2)}</pre> */}
          {/* ; */}
          <Img fluid={image} style={{ width: '100%', height: '100%' }} />
          <Box
            style={{
              overflow: 'hidden',
              // marginBottom: '10px',
              position: 'absolute',
              zIndex: 2,
              textAlign: 'center',
              display: 'flex',
            }}
          >
            {/* <motion.div
              style={{ margin: 0 }}
              variants={{
                show: {
                  y: '3rem',
                  transition: {
                    delayChildren: 0.5,
                  },
                },
                hide: {
                  y: '6rem',
                },
              }}
            >
              <Heading as="div" variant="h2" color="white">
                {projectName}
              </Heading>
            </motion.div> */}
            <motion.div
              style={{ margin: 0 }}
              variants={{
                show: {
                  y: '5rem',
                },
                hide: {
                  y: '0rem',
                },
              }}
            >
              <Heading as="div" variant="nextProjectTeaserLabel" color="white">
                Next Project
              </Heading>
            </motion.div>
          </Box>
          <motion.div
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              // background: 'pig',
              zIndex: 1,
              right: 0,
              // opacity: 0.5,
            }}
            sx={{ backgroundColor: 'orange' }}
            variants={{
              show: {
                width: 0,
                transitionEnd: {
                  right: 'auto',
                  left: 0,
                },
              },
              hide: {
                width: '100%',
                transitionEnd: {
                  left: 'auto',
                  right: 0,
                },
              },
            }}
          />
        </motion.div>
      </Link>
    </Flex>
  );
};

export default NextProjectTeaser;
