import React from 'react';
import PropTypes from 'prop-types';

import { Text } from 'theme-ui';

const Heading = ({ variant, as, color, children, ...props }) => (
  <Text variant={variant} as={as} color={color} {...props}>
    {children}
  </Text>
);

Heading.propTypes = {
  variant: PropTypes.oneOf(['h1', 'h2']),
  color: PropTypes.string,
  as: PropTypes.string,
  children: PropTypes.string.isRequired,
};

Heading.defaultProps = {
  variant: 'h1',
  as: 'h1',
  color: '#3B4AB6',
};

export default Heading;
