/** @jsx jsx */
import React, { useState } from 'react';
import { Flex, Grid, Box, Text, jsx } from 'theme-ui';
import { useInView } from 'react-intersection-observer';
import { ReactComponent as IconFooter } from '../../assets/images/iconFooter.svg';
import { ReactComponent as IconHeart } from '../../assets/images/heart.svg';
import Heading from '../Heading';
import Heart from '../Heart';
import NextProjectTeaser from '../NextProjectTeaser';
import Link from '../Link';
import Schlingeling from '../Schlingeling';

const Footer = ({ nextProject }) => {
  const [showHeart, setShowHeart] = useState(false);

  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });

  return (
    <>
      {nextProject ? (
        <NextProjectTeaser
          slug={nextProject.next.slug.current}
          projectName={nextProject.next.title}
          color={nextProject.next.backgroundColor}
          image={nextProject.next.nextImage.asset.fluid}
        />
      ) : null}
      <Box
        ref={ref}
        sx={{
          width: '100vw',
          backgroundColor: 'blue',
          borderTop: '1px solid',
          borderColor: ['transparent', 'white'],
        }}
      >
        <Grid
          sx={{
            // justifyContent: 'space-evenly',
            // alignItems: 'center',
            gridTemplateColumns: ['1fr', '1fr 1fr', null, '1fr 1fr 1fr'],
            gridTemplateRows: ['1fr 1fr 1fr', '1fr'],
            height: [30, '170px'],
            // height: '100%',
            maxWidth: 240,
            px: [5, 10, 14],
            py: [5, 0],
            margin: 'auto',
            flexDirection: ['column', 'row'],
          }}
        >
          <Flex
            sx={{ alignItems: 'center', display: ['none', null, null, 'flex'] }}
          >
            <IconFooter
              sx={{
                width: ['182.88px'],
                height: ['60px', '88px'],
              }}
            />
          </Flex>
          <Flex
            sx={{
              alignItems: ['center'],
              justifyContent: ['center', 'left', null, 'center'],
            }}
          >
            <Heading
              as="div"
              variant="h2"
              color="white"
              sx={{ textAlign: ['center', 'left', null, 'center'] }}
            >
              <Link to="mailto:sabrina@somewhere-south.com">Get in touch</Link>
            </Heading>
          </Flex>
          <Box sx={{ display: ['block', 'none'], mx: 'auto' }}>
            <Schlingeling leftColor="white" rightColor="pig" />
          </Box>
          <Flex
            sx={{
              justifyContent: ['center', 'flex-end'],
              alignItems: 'center',
              display: ['flex'],
            }}
          >
            <Text
              variant="text.body"
              sx={{
                color: 'white',
                // textAlign: 'center'
              }}
            >
              <Link
                to="http://kevinfoerster.com"
                target="blank"
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  // textAlign: 'center',
                }}
              >
                Coded with
                <Heart style={{ margin: '-25px' }} show={inView} />
                by Kevin Förster
              </Link>
            </Text>
          </Flex>
        </Grid>
      </Box>
    </>
    // <Grid
    //   columns={[
    //     1,
    //     'minmax(0em, 1fr) repeat(2, minmax(0, 720px)) minmax(0em, 1fr)',
    //   ]}
    //   gap={0}
    //   sx={{ gridTemplateRows: ['1fr 1fr', '1fr'] }}
    // >
    //   <Box
    //     sx={{
    //       backgroundColor: 'orange',
    //       display: ['none', 'block'],
    //       gridRowStart: 1,
    //       overflow: 'hidden',
    //     }}
    //   />
    //   <Link
    //     to="mailto:sabrina@somewhere-south.com"
    //     sx={{
    //       display: 'flex',
    //       width: '100%',
    //       justifyContent: 'center',
    //       alignItems: 'center',
    //       backgroundColor: 'orange',
    //       gridRowStart: [2, 1],
    //       height: ['156px', '300px'],
    //       position: 'relative',
    //     }}
    //   >
    //     <IconFooter
    //       sx={{
    //         position: 'absolute',
    //         top: [3, 6],
    //         right: [3, 6],
    //         width: ['85.2px', '182.88px'],
    //         height: ['41px', '88px'],
    //       }}
    //     />

    //   </Link>

    //   {nextProject ? (
    //     <NextProjectTeaser
    //       slug={nextProject.next.slug.current}
    //       projectName={nextProject.next.title}
    //       color={nextProject.next.backgroundColor}
    //       image={nextProject.next.mainImage.asset.fluid}
    //     />
    //   ) : (
    //     <Flex
    //       sx={{
    //         width: '100%',
    //         justifyContent: 'center',
    //         alignItems: 'center',
    //         backgroundColor: 'pig',
    //         gridRowStart: [1, 1],
    //         height: ['156px', '300px'],
    //       }}
    //     >
    //       <Link to="/projects">
    //         <Heading as="div" variant="h2" color="white">
    //           Projects
    //         </Heading>
    //       </Link>
    //     </Flex>
    //   )}

    //   <Box
    //     sx={{
    //       backgroundColor: nextProject?.next?.backgroundColor?.hex || 'pig',
    //       display: ['none', 'block'],
    //       gridRowStart: 1,
    //       overflow: 'hidden',
    //     }}
    //   />
    // </Grid>
  );
};

export default Footer;
