import PropTypes from 'prop-types';
import React from 'react';
import { motion } from 'framer-motion';
// import { useThemeUI } from 'theme-ui';
import theme from '../../gatsby-plugin-theme-ui';

const variants = {
  root: {
    idle: {
      transition: {
        // staggerChildren: 0.01
      },
    },
    active: {
      transition: {
        delayChildren: 0.1,
      },
    },
  },
  heart: {
    idle: {
      scale: 0.0,
      fill: theme.colors.orange,
    },
    active: {
      scale: 1.2,
      fill: theme.colors.pig,
    },
  },
  outerCircle: {
    idle: {
      r: 0,
      opacity: 0,
      strokeWidth: [0, 20],
      stroke: theme.colors.mustard,
      transition: {
        delay: 0.05,
      },
    },
    active: {
      r: 20,
      opacity: 1,
      strokeWidth: 0,
      stroke: theme.colors.orange,
      transition: {
        delay: 0.03,
      },
    },
  },
  smallDot: {
    idle: {
      y: 0,
      scale: 0,
      opacity: 1,
    },
    active: {
      y: -6,
      scale: 1,
      opacity: 0,
      transition: {
        type: 'spring',
        stiffness: 700,
        damping: 80,
      },
    },
  },
  bigDot: {
    idle: {
      y: 2,
      scale: 0,
      opacity: 1,
      transition: {
        delay: 0,
      },
    },
    active: {
      y: -4,
      scale: 1.4,
      opacity: 0,
      transition: {
        delay: 0.08,
        type: 'spring',
        stiffness: 700,
        damping: 80,
      },
    },
  },
  dotGroup: {
    idle: {
      rotate: 0,
    },
    active: {
      rotate: 5,
    },
  },
  smallDotGroup: {
    idle: {
      rotate: 2,
      transformOrigin: '40.5 40.5',
    },
    active: {
      rotate: 8,
      transformOrigin: '40.5 40.5',
    },
  },
};

const Dots = ({ rotation }) => {
  return (
    <g transform={`rotate(${rotation} 40.5 40.5)`}>
      <motion.circle
        fill={theme.colors.orange}
        cx={39}
        cy={20}
        r={3}
        initial="idle"
        // animate={localVariants}
        variants={variants.bigDot}
      />
    </g>
  );
};
const SmallDot = ({ rotation }) => (
  <g transform={`rotate(${rotation} 40.5 40.5)`}>
    <g transform="rotate(5 40.5 40.5) translate(0, 4)">
      <motion.circle
        fill={theme.colors.mustard}
        cx={39}
        cy={20}
        r={3}
        initial="idle"
        variants={variants.smallDot}
      />
    </g>
  </g>
);

function SvgHeart(props) {
  const { show } = props;
  return (
    <motion.svg
      width={81}
      height={81}
      animate={show ? 'active' : 'idle'}
      variants={variants.root}
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <motion.path
          d="M47.269 34.077c-1.934-1.653-4.781-1.336-6.574.492l-.668.703-.703-.703c-1.758-1.829-4.641-2.145-6.575-.492-2.215 1.898-2.32 5.273-.351 7.312l6.82 7.031c.422.457 1.16.457 1.582 0l6.82-7.03c1.97-2.04 1.864-5.415-.351-7.313z"
          fill={theme.colors.pig}
          fillRule="nonzero"
          // whileHover={{ scale: 1.2 }}
          variants={variants.heart}
          initial="idle"
          // animate={"active"}
        />
        <g>
          <motion.circle
            // fill="black"
            cx={40.5}
            cy={40.5}
            r={1}
            initial="idle"
            variants={variants.outerCircle}
          />
        </g>
        <motion.g initial="idle" variants={variants.dotGroup}>
          <Dots rotation={Math.floor((360 / 7) * 1)} />
          <Dots rotation={Math.floor((360 / 7) * 2)} />
          <Dots rotation={Math.floor((360 / 7) * 3)} />
          <Dots rotation={Math.floor((360 / 7) * 4)} />
          <Dots rotation={Math.floor((360 / 7) * 5)} />
          <Dots rotation={Math.floor((360 / 7) * 6)} />
          <Dots rotation={Math.floor((360 / 7) * 7)} />
        </motion.g>
        <motion.g initial="idle" variants={variants.smallDotGroup}>
          <SmallDot rotation={Math.floor((360 / 7) * 1)} />
          <SmallDot rotation={Math.floor((360 / 7) * 2)} />
          <SmallDot rotation={Math.floor((360 / 7) * 3)} />
          <SmallDot rotation={Math.floor((360 / 7) * 4)} />
          <SmallDot rotation={Math.floor((360 / 7) * 5)} />
          <SmallDot rotation={Math.floor((360 / 7) * 6)} />
          <SmallDot rotation={Math.floor((360 / 7) * 7)} />
        </motion.g>
      </g>
    </motion.svg>
  );
}

SvgHeart.propTypes = {
  show: PropTypes.bool,
};

SvgHeart.defaultProps = {
  show: true,
};

export default SvgHeart;
