import React, { useState } from 'react';
import { motion } from 'framer-motion';

const Burger = ({ clickHandler }) => {
  const [burger, setBurger] = useState('close');
  const topLine = {
    open: {
      rotate: -45,
      x1: Math.sqrt(2 * (16 * 16)) / 2 - 4 + 2,
    },
    close: {
      rotate: 0,
      x1: 8,
    },
  };

  const middleLine = {
    open: {
      rotate: 45,
      x1: 12 - Math.sqrt(2 * (16 * 16)) / 2,
      x2: 12 + Math.sqrt(2 * (16 * 16)) / 2,
    },
    close: {
      rotate: 0,
      x1: 4,
      x2: 20,
    },
  };
  const bottomLine = {
    open: {
      rotate: -45,
      x1: 4,
      x2: Math.sqrt(2 * (16 * 16)) / 2 + 4 - 1,
    },
    close: {
      rotate: 0,
      x1: 8,
      x2: 20,
    },
  };
  return (
    <motion.svg
      viewBox="0 0 24 24"
      style={{ width: '24px', height: '24px' }}
      onClick={() => {
        setBurger(burger === 'open' ? 'close' : 'open');
        clickHandler();
      }}
      animate={burger}
    >
      <motion.line
        x1="8"
        y1="4"
        x2="20"
        y2="4"
        strokeWidth="2"
        stroke="currentColor"
        strokeLinecap="round"
        variants={topLine}
        // transition={{
        //   type: 'spring',
        //   damping: 10,
        //   stiffness: 100,
        // }}
        style={{ originX: '20px', originY: '4px' }}
      />
      <motion.line
        x1="4"
        y1="12"
        x2="20"
        y2="12"
        strokeWidth="2"
        stroke="currentColor"
        strokeLinecap="round"
        variants={middleLine}
      />
      <motion.line
        x1="8"
        y1="20"
        x2="20"
        y2="20"
        strokeWidth="2"
        stroke="currentColor"
        strokeLinecap="round"
        variants={bottomLine}
        style={{ originX: '4px', originY: '20px' }}
      />
    </motion.svg>
  );
};

export default Burger;
