/** @jsx jsx */
import PropTypes from 'prop-types';
import { jsx, Box } from 'theme-ui';
import { Link } from 'gatsby';

import logoDark, {
  ReactComponent as LogoSVG,
} from '../../assets/images/logoDark.svg';

const Logo = ({ appearance }) => (
  <Link to="/">
    <Box
      sx={{
        width: ['35px', '49.84px', '254.67px'],
        height: ['35px', '49.84px'],
      }}
    >
      <LogoSVG
        sx={{
          width: ['178.83px', '254.67px', '100%'],
          height: '100%',
          '.logoDark_svg__text': {
            display: ['none', null, 'block'],
            color: appearance === 'dark' ? 'blue' : 'white',
          },
        }}
      />
    </Box>
  </Link>
);

Logo.propTypes = {
  appearance: PropTypes.oneOf(['dark', 'light']),
};
Logo.defaultProps = {
  appearance: 'dark',
};

export default Logo;
