/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Link as GatsbyLink } from 'gatsby';

const Link = (props) => {
  let linkOutput;
  if (props.to.includes(':')) {
    linkOutput = (
      <a
        href={props.to}
        {...props}
        sx={{
          textDecoration: 'none',
          color: 'inherit',
          ' &.active': {
            color: 'primary',
          },
          ...props.sx,
        }}
      />
    );
  } else {
    linkOutput = (
      <GatsbyLink
        {...props}
        activeClassName="active"
        sx={{
          textDecoration: 'none',
          color: 'inherit',
          '&.active': {
            color: 'primary',
          },
          ...props.sx,
        }}
      />
    );
  }
  return linkOutput;
};

export default Link;
